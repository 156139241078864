import React, { useState } from 'react';
import Footer from './footer';

function Partner() {
  const [partnerformData, setPartnerFormData] = useState({
    email: '',
    workshopName: '',
    city: '',
    state: '',
    registrationCode: '',
    ownerName: '',
    mobileNo: '',
    workshopAddress: '',
    workshopGmapLink: '',
    gstStatus: '',
    workedWithG4G: ''
  });

  const [formErrors, setFormErrors] = useState({}); // Define formErrors state

  const handleChange = (e) => {
    setPartnerFormData({ ...partnerformData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log('Form data:', partnerformData);
  };

  const validateForm = () => {
    let errors = {};
    let formIsValid = true;

    // Email validation
    if (!partnerformData.email.trim()) {
      formIsValid = false;
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(partnerformData.email)) {
      formIsValid = false;
      errors.email = 'Invalid email address';
    }

    // Workshop Name validation
    if (!partnerformData.workshopName.trim()) {
      formIsValid = false;
      errors.workshopName = 'Workshop name is required';
    }

    // City validation
    if (!partnerformData.city.trim()) {
      formIsValid = false;
      errors.city = 'City is required';
    }

    // State validation
    if (!partnerformData.state.trim()) {
      formIsValid = false;
      errors.state = 'State is required';
    }

    // Registration Code validation
    if (!partnerformData.registrationCode.trim()) {
      formIsValid = false;
      errors.registrationCode = 'Registration code is required';
    }

    // Owner Name validation
    if (!partnerformData.ownerName.trim()) {
      formIsValid = false;
      errors.ownerName = 'Owner name is required';
    }

    // Mobile Number validation
    if (!partnerformData.mobileNo.trim()) {
      formIsValid = false;
      errors.mobileNo = 'Mobile number is required';
    }

    // Workshop Address validation
    if (!partnerformData.workshopAddress.trim()) {
      formIsValid = false;
      errors.workshopAddress = 'Workshop address is required';
    }

    // Workshop Google Maps Link validation
    if (!partnerformData.workshopGmapLink.trim()) {
      formIsValid = false;
      errors.workshopGmapLink = 'Workshop Google Maps link is required';
    }

    // GST Status validation
    if (!partnerformData.gstStatus.trim()) {
      formIsValid = false;
      errors.gstStatus = 'GST status is required';
    }

    // Worked with G4G validation
    if (!partnerformData.workedWithG4G.trim()) {
      formIsValid = false;
      errors.workedWithG4G = 'Please select if you have worked with G4G';
    }

    setFormErrors(errors);
    return formIsValid;
  };
  return (
    <div className="w-full ">
      <h1 className="text-5xl font-semibold text-brandPrimary m-8">Partner with us</h1>
      <form onSubmit={handleSubmit}>
        <div className="m-8">
          <label htmlFor="email" className="block font-bold mb-2">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={partnerformData.email}
            onChange={handleChange}
            className="border rounded px-4 py-2 w-full"
            required
          />
        </div>
        <div className="m-8">
          <label htmlFor="workshopName" className="block font-bold mb-2">Workshop Name:</label>
          <input
            type="text"
            id="workshopName"
            name="workshopName"
            value={partnerformData.workshopName}
            onChange={handleChange}
            className="border rounded px-4 py-2 w-full"
            required
          />
        </div>
        <div className="m-8">
          <label htmlFor="city" className="block font-bold mb-2">City:</label>
          <input
            type="text"
            id="city"
            name="city"
            value={partnerformData.city}
            onChange={handleChange}
            className="border rounded px-4 py-2 w-full"
            required
          />
        </div>
        <div className="m-8">
          <label htmlFor="state" className="block font-bold mb-2">State:</label>
          <input
            type="text"
            id="state"
            name="state"
            value={partnerformData.state}
            onChange={handleChange}
            className="border rounded px-4 py-2 w-full"
            required
          />
        </div>
        <div className="m-8">
          <label htmlFor="registrationCode" className="block font-bold mb-2">Registration Code (HR-26, RJ-14 Etc...):</label>
          <input
            type="text"
            id="registrationCode"
            name="registrationCode"
            value={partnerformData.registrationCode}
            onChange={handleChange}
            className="border rounded px-4 py-2 w-full"
            required
          />
        </div>
        <div className="m-8">
          <label htmlFor="ownerName" className="block font-bold mb-2">Owner Name:</label>
          <input
            type="text"
            id="ownerName"
            name="ownerName"
            value={partnerformData.ownerName}
            onChange={handleChange}
            className="border rounded px-4 py-2 w-full"
            required
          />
        </div>
        <div className="m-8">
          <label htmlFor="mobileNo" className="block font-bold mb-2">Mobile Number:</label>
          <input
            type="tel"
            id="mobileNo"
            name="mobileNo"
            value={partnerformData.mobileNo}
            onChange={handleChange}
            className="border rounded px-4 py-2 w-full"
            required
          />
        </div>
        <div className="m-8">
          <label htmlFor="workshopAddress" className="block font-bold mb-2">Workshop Address:</label>
          <textarea
            id="workshopAddress"
            name="workshopAddress"
            value={partnerformData.workshopAddress}
            onChange={handleChange}
            className="border rounded px-4 py-2 w-full"
            rows="3"
            required
          ></textarea>
        </div>
        <div className="m-8">
          <label htmlFor="workshopGmapLink" className="block font-bold mb-2">Workshop Google Maps Link:</label>
          <input
            type="url"
            id="workshopGmapLink"
            name="workshopGmapLink"
            value={partnerformData.workshopGmapLink}
            onChange={handleChange}
            className="border rounded px-4 py-2 w-full"
            required
          />
        </div>
        <div className="m-8">
          <label htmlFor="gstStatus" className="block font-bold mb-2">GST Status:</label>
          <select
            id="gstStatus"
            name="gstStatus"
            value={partnerformData.gstStatus}
            onChange={handleChange}
            className="border rounded px-4 py-2 w-full"
            required
          >
            <option value="">Select GST Status</option>
            <option value="registered">Registered</option>
            <option value="unregistered">Unregistered</option>
          </select>
        </div>
        <div className="m-8">
          <label htmlFor="workedWithG4G" className="block font-bold mb-2">Have you ever worked with G4G?</label>
          <select
            id="workedWithG4G"
            name="workedWithG4G"
            value={partnerformData.workedWithG4G}
            onChange={handleChange}
            className="border rounded px-4 py-2 w-full"
            required
          >
            <option value="">Select an option</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </div>
        <button
          type="submit"
          className="bg-orange-500 hover:bg-purple-700 text-white font-bold py-2 px-4 mx-8 mb-4 rounded"
        >
          Submit
        </button>
      </form>
      <Footer />
    </div>
  );
}

export default Partner;