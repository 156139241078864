import React from 'react';
import banimg from '../assets/banimg.png';

function Content2() {
    return (
        <div>
             <img src={banimg} alt="Image" className='p-8 mt-12 mb-12' />
        </div>
    );
}

export default Content2;