import React from 'react';
import Footer from './footer';

function AboutUs() {
  return (
    <div className="px-8 mt-8">
      <section className="mb-8">
        <h1 className="text-5xl font-semibold text-brandPrimary mb-4">About Market</h1>
        <p className="text-2xl font-semibold text-gray-800">
          With more and more vehicles entering the aftermarket each year, the turnout in authorised service centres for servicing needs is quite low because of the hefty charges. Most opt for a multi brand workshop nearby, but there are apprehensions about service quality. That is where we come in. We are the novel solution to the complex problem of car servicing!
        </p>
      </section>

      <section className="mb-8">
        <h1 className="text-5xl font-semibold text-brandPrimary mb-4">About Our Founder</h1>
        <p className="text-2xl font-semibold text-gray-800">
          Go4Garage was founded in year 2021, by Mr. Vivek Gupta after working in this industry for five years, he identified this gap that was still left wide open with no player to cater to the specific need of fleet-based car companies. Hence, with a background in Mechanical Engineering, combined with the industry experience, with this company, he has made pioneering efforts in auto repair market in B2B space.
        </p>
      </section>

      <section className="mb-8">
        <h1 className="text-5xl font-semibold text-brandPrimary mb-4">About Us</h1>
        <p className="text-2xl font-semibold text-gray-800 mb-4">
          Go4Garage is a tech fuelled start-up, with a vision to become a multi-brand automobile after sales service management facilitator with a Pan India presence. We aim to create a platform that seamlessly connects customers and service providers. We ensure that the prices are competitive while maintaining the service quality for their vehicle with our original spare parts network, skilled labour and state of the art equipment. Thus, curbing customers woes of information asymmetry and quality without having to burn a hole in their pockets.
        </p>
        <p className="text-2xl font-semibold text-gray-800 mb-4">
          Our platform is multifaceted. We are fostering a chain of multi-brand franchisee workshops that cater to the special needs of fleet owners. We also act as Software as a Service (SaaS) provider for all the stakeholders in the auto repair landscape. Additionally, we also work towards the upscaling of our partner workshops through all financial, marketing and upskilling support.
        </p>
        <p className="text-2xl font-semibold text-gray-800">
          With our chain of franchisee workshops, we strive towards organising the unorganised vehicle servicing market. This ensures that customers pay a fair price for an assured service quality that holds up to the industry standards. In the process, we maintain complete price transparency and adherence to time. We go beyond that by partnering with insurance companies, spare parts manufacturers (OEM), and EV charging companies to make the experience smooth.
        </p>
        <p className="text-2xl font-semibold text-gray-800 mt-4">
          As a SaaS provider, we offer end-to-end solutions for all players in the auto repair ecosystem i.e. hosts, fleet owners, insurance companies, workshops, spare parts suppliers, etc. This not only helps maintain complete transparency in the ecosystem but also aids the stakeholders in their tracking and accounting needs.
        </p>
      </section>

      <section className="mb-8">
        <h1 className="text-5xl font-semibold text-brandPrimary mb-4">Go4Garage - EV</h1>
        <p className="text-2xl font-semibold text-gray-800">
          As a new age conscious start-up, we are constantly doing our bit to pivot the industry into a green one, through this vertical of ours. We possess capabilities to service all the new age electrical vehicles. Our equipment, labour skill and infrastructure are updated to keep in step with the changing times.
        </p>
      </section>

      <section>
        <h1 className="text-5xl font-semibold text-brandPrimary mb-4">Go4Garage - School of Vehicle Maintenance</h1>
        <p className="text-2xl font-semibold text-gray-800">
          Part of organising the sector also includes empowering the backbone of the industry i.e. mechanics with tangible, updated and recognised skills and certifications. A skilled workforce is not only a green sign for the economy but also has unmatched precision that helps us deliver quality service in one go.
        </p>
      </section>
      <Footer />
    </div>
  );
}

export default AboutUs;