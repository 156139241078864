import React from 'react';
import generalservices from '../assets/generalservices.png';
import evvehiclerepair from '../assets/evvehiclerepair.png';
import roadsideassistance from '../assets/roadsideassistance.png';
import sparepartsupply from '../assets/sparepartsupply.png';
import dentingpainting from '../assets/dentingpainting.png';
import insuranceclaimfacility from '../assets/insuranceclaimfacility.png';
import mechanicalrepairwork from '../assets/mechanicalrepairwork.png';
import perodicmaintainance from '../assets/perodicmaintainance.png';
import evcharging from '../assets/evcharging.png';
import salvage from '../assets/salvage.png';
import Footer from './footer';

function Services() {
  return (
    <div>
      <section className="mb-8">
        <h1 className="text-5xl font-semibold text-brandPrimary m-8">Why Choose Us?</h1>
        <p className="text-2xl font-semibold text-gray-800 m-8">
          We are a one-stop shop for all your car care needs. Our commitment to quality service, fair pricing, and customer satisfaction makes us the trusted choice for car owners. Contact us today to schedule an appointment or learn more about our services!
        </p>
        <p className="text-2xl font-semibold text-gray-800 mt-4 m-8">
          At our multi-brand car repair workshop, we offer everything you need to keep your vehicle running smoothly and looking sharp. Here is a breakdown of the services we provide:
        </p>
      </section>

      <section className="flex flex-wrap items-center mb-8">
        <div className="w-full md:w-1/2 px-8">
          <h3 className="text-4xl font-semibold text-brandPrimary mb-4">General Services</h3>
          <p className="text-2xl font-semibold text-gray-800">
            From oil changes and tune-ups to filter replacements and fluid top-offs, our expert technicians handle all your routine maintenance needs to ensure your car performs at its best.
          </p>
        </div>
        <div className="w-full md:w-1/2 px-8">
          <img src={generalservices} alt="General services" className="h-full w-full object-cover ml-4 md:w-full sm:ml-4" />
        </div>
      </section>

      <section className="flex flex-wrap items-center mb-8">
        <div className="w-full md:w-1/2 px-8 md:order-2">
          <h3 className="text-4xl font-semibold text-brandPrimary mb-4">Roadside Assistance</h3>
          <p className="text-2xl font-semibold text-gray-800">
            A flat tire or a dead battery can leave you stranded in the middle of nowhere. However, with our Pan India presence and roadside assistance program, we can ensure both, yours and your vehicles safety.
          </p>
        </div>
        <div className="w-full md:w-1/2 px-8 md:order-1">
          <img src={roadsideassistance} alt="Roadside assistance" className="w-full" />
        </div>
      </section>

      <section className="flex flex-wrap items-center mb-8">
        <div className="w-full md:w-1/2 px-8">
          <h3 className="text-4xl font-semibold text-brandPrimary mb-4">EV Vehicle Repair</h3>
          <p className="text-2xl font-semibold text-gray-800">
            We are equipped to diagnose and repair all types of electric vehicles, keeping your eco-friendly ride running smoothly.
          </p>
        </div>
        <div className="w-full md:w-1/2 px-8">
          <img src={evvehiclerepair} alt="EV Vehicle Repair" className="w-full" />
        </div>
      </section>

      <section className="flex flex-wrap items-center mb-8">
        <div className="w-full md:w-1/2 px-8 md:order-2">
          <h3 className="text-4xl font-semibold text-brandPrimary mb-4">EV Charging</h3>
          <p className="text-2xl font-semibold text-gray-800">
            Your urgency becomes our priority when you come with charging needs. We offer convenient EV charging stations to keep your electric car powered up and ready to go. We host charging stations with speeds of all kinds.
          </p>
        </div>
        <div className="w-full md:w-1/2 px-8 md:order-1">
          <img src={evcharging} alt="EV Charging" className="w-full" />
        </div>
      </section>

      <section className="flex flex-wrap items-center mb-8">
        <div className="w-full md:w-1/2 px-8">
          <h3 className="text-4xl font-semibold text-brandPrimary mb-4">Spare Parts Supply</h3>
          <p className="text-2xl font-semibold text-gray-800">
            We stock a wide range of genuine and high-quality spare parts for all car brands and models. Our supplier network provides for steady flow of these parts in the pipeline. Get the parts you need to get your car back in top shape.
          </p>
        </div>
        <div className="w-full md:w-1/2 px-8">
          <img src={sparepartsupply} alt="Spare Parts Supply" className="w-full" />
        </div>
      </section>

      <section className="flex flex-wrap items-center mb-8">
        <div className="w-full md:w-1/2 px-8 md:order-2">
          <h3 className="text-4xl font-semibold text-brandPrimary mb-4">Denting & Painting</h3>
          <p className="text-2xl font-semibold text-gray-800">
            Minor dents, scratches, or faded paint, our expert technicians can restore your cars exterior to its original showroom shine.
          </p>
        </div>
        <div className="w-full md:w-1/2 px-8 md:order-1">
          <img src={dentingpainting} alt="Denting & Painting" className="w-full" />
        </div>
      </section>

      <section className="flex flex-wrap items-center mb-8">
        <div className="w-full md:w-1/2 px-8">
          <h3 className="text-4xl font-semibold text-brandPrimary mb-4">Insurance Claim Facility</h3>
          <p className="text-2xl font-semibold text-gray-800">
            Dealing with insurance claims after an accident can be stressful. We handle the paperwork and repairs seamlessly, so you can focus on getting back on the road.
          </p>
        </div>
        <div className="w-full md:w-1/2 px-8">
          <img src={insuranceclaimfacility} alt="Insurance Claim Facility" className="w-full" />
        </div>
      </section>

      <section className="flex flex-wrap items-center mb-8">
        <div className="w-full md:w-1/2 px-8 md:order-2">
          <h3 className="text-4xl font-semibold text-brandPrimary mb-4">Mechanical & Repair Work</h3>
          <p className="text-2xl font-semibold text-gray-800">
            Our highly skilled mechanics can diagnose and repair any mechanical issue, big or small, to ensure your car runs safely and reliably.
          </p>
        </div>
        <div className="w-full md:w-1/2 px-8 md:order-1">
          <img src={mechanicalrepairwork} alt="Mechanical & Repair Work" className="w-full" />
        </div>
      </section>

      <section className="flex flex-wrap items-center mb-8">
        <div className="w-full md:w-1/2 px-8">
          <h3 className="text-4xl font-semibold text-brandPrimary mb-4">Salvage</h3>
          <p className="text-2xl font-semibold text-gray-800">
            We understand that accidents happen. If your car is beyond repair, we can help you navigate the salvage process.
          </p>
        </div>
        <div className="w-full md:w-1/2 px-8">
          <img src={salvage} alt="Salvage" className="w-full" />
        </div>
      </section>

      <section className="flex flex-wrap items-center mb-8">
        <div className="w-full md:w-1/2 px-8 md:order-2">
          <h3 className="text-4xl font-semibold text-brandPrimary mb-4">Periodic Maintenance Service</h3>
          <p className="text-2xl font-semibold text-gray-800">
            Regular maintenance is key to preventing costly repairs down the road. Our periodic maintenance plans ensure your car receives the TLC it needs to stay in top condition.
          </p>
        </div>
        <div className="w-full md:w-1/2 px-8 md:order-1">
          <img src={perodicmaintainance} alt="Periodic Maintenance Service" className="w-full" />
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Services;