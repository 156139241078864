import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import { FaTimes, FaBars } from "react-icons/fa";

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const navItems = [
    { link: 'Home', path: '/' },
    { link: 'About Us', path: '/aboutUs' },
    { link: 'Why Choose Us?', path: '/services' },
    { link: 'Partner with Us', path: '/partner', isButton: true },
    { link: '7703843351', isContact: true },
  ];

  const handleContactClick = () => {
    alert('Contact us at 7703843351');
  };

  return (
    <header style={{ backgroundColor: isSticky ? '#ffffff' : '#F5F5F5' }} className='w-full sticky top-0 left-0 right-0 shadow-2xl'>
      <nav>
        <div className='flex items-center justify-between flex-wrap p-6'>
          <Link to="/" className='flex items-center space-x-3'>
            <img src={logo} alt='logo' className='w-48 h-15 inline-block items-center' />
          </Link>

          <div className='sm:hidden'>
            <button onClick={toggleMenu} className='text-black focus:outline-none focus:text-grayDNeutral'>
              {isMenuOpen ? <FaTimes className='h-8 w-8 text-grayDNeutral' /> : <FaBars className='h-8 w-8 text-grayDNeutral' />}
            </button>
          </div>

          <div className={`sm:flex sm:space-x-8 ${isMenuOpen ? 'block' : 'hidden'} `}>
            {navItems.map((item, index) => (
              <React.Fragment key={index}>
                {item.isContact ? (
                  <span className='px-3 py-2 text-gray-700 cursor-pointer' onClick={handleContactClick}>{item.link}</span>
                ) : (
                  <Link to={item.path} className='px-3 py-2 text-gray-700 hover:text-purple-700'>{item.link}</Link>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Navbar;